export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novosti"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodi"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naš tim"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerviši"])},
  "nav_news_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto kompanije trebaju DMS?"])},
  "nav_news_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistem baziran na web-u"])},
  "nav_news_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API integracija"])},
  "nav_news_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalizacija"])},
  "nav_news_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kako ERP sistem moze pomoci malom biznisu da raste ?"])},
  "nav_news_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znakovi da je Vašem poslovanju potrebno naprednije softversko rješenje"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "faq-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promocije"])},
  "widget_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli"])},
  "widget_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za sva pitanja pozovite"])},
  "widget_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33 774 873"])},
  "widget_icon_one_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručnjaka"])},
  "widget_icon_two_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješnih projekata"])},
  "widget_icon_three_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godina iskustva"])},
  "home_icon_square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brza i jednostavna rješenja"])},
  "home_icon_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša kompanija pod jednim krovom"])},
  "home_icon_companies_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralno upravljanje procesima."])},
  "home_icon_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistem baziran na web-u"])},
  "home_icon_cloud_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, WEB based, MySQL."])},
  "home_icon_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi procesi u jednom toku"])},
  "home_icon_config_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapiranje stvarnih procesa u digitalne."])},
  "home_icon_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderan koncept, jednostavan dizajn"])},
  "home_icon_circle_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podržava jednostavnu integraciju s drugim rješenjima."])},
  "home_forspace_erp_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP & Računovodstvo"])},
  "home_forspace_erp_accounting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odluke zasnovane na činjenicama, a ne na pretpostavkama!"])},
  "recension_primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzije"])},
  "recension_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šta naši klijenti kažu o nama"])},
  "recension_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sve naše klijente ponosno ističemo i gradimo partnerske odnose na obostrano zadovoljstvo. Ukoliko neki od naših klijenata nisu još uvijek dodani na listu, ne znači da ih manje cijenimo ili da su nam manje važni."])},
  "recension_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da li imate nekih pitanja ?"])},
  "recension_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas."])},
  "about_us_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "about_us_news_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novosti"])},
  "about_us_video_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])},
  "about_us_video_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogledajte naš kratki video"])},
  "about_us_nums_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na kraju 2022 godine naši pokazatelji su: "])},
  "about_us_nums_established": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osnovano"])},
  "about_us_nums_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klijeni"])},
  "about_us_nums_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaposlenih"])},
  "products_purchase_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabava"])},
  "products_sales_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja"])},
  "products_warehouse_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladište"])},
  "contact_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošaljite nam poruku"])},
  "contact_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše ime"])},
  "contact_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš email"])},
  "contact_form_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text poruke"])},
  "contact_form_nameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite Vaše ime"])},
  "contact_form_emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite Vaš email"])},
  "contact_form_messageInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite poruku"])},
  "contact_form_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta"])},
  "footer_forspace_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Proizvodi"])},
  "footer_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["računovodstvo"])},
  "footer_controlling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroling"])},
  "footer_human_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ljudski resursi"])},
  "footer_all_in_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all-in-one erp rješenje"])},
  "footer_sales_menagment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upravljenje prodajom"])},
  "footer_mobile_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobilne aplikacije"])},
  "footer_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zakon o zaštiti"])},
  "footer_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Usluge"])},
  "footer_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas"])},
  "footer_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulevar Meše Selimovića 16"])},
  "footer_address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosna i Hercegovina"])},
  "footer_address_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33/770-949"])},
  "footer_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Forspace - All Rights Reserved"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji poruku"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabava"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodnja"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladište"])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saznaj više"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji"])},
  "carina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carina"])},
  "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "widget_main_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA Šped je osnovan 2003.godine sa 5 uposlenika kao jedna od članica ASA PREVENT grupacije sa osnovnim aktivnostima u podrucju medjunarodne špedicije, transporta i skladistenja. Od početka našeg djelovanja strategija je bila razvijanje i jačanje ljudskih i materijalnih resursa prvenstveno razvijajući logistiku u bransi autoindustrije, a  nakon toga i u  ostalim granama privrede. Trenutno ASA ŠPED ima 43 uposlenika, koji su vrhunski stručnjaci u oblasti usluga koje nudimo. Timski rad i izuzetni medjuljudski odnosi medju nasim uposlenicima nasa su najjaca vrijednost."])},
  "fast_and_secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzi i sigurni!"])},
  "fast_and_simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzo i jednostavno"])},
  "find_us_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronađite nas na karti"])},
  "duty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carinjenje"])},
  "transport_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svim našim klijentima pružamo usluge međunarodnog i domaćeg transporta, a specijalizirali smo se i u hitnoj distribuciji robe do kupaca unutar BiH."])},
  "warehouse_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA ŠPED  raspolaže sa cca 20.000 m² zatvorenog skladišnog prostora pogodnog za skladištenje svih vrsta roba."])},
  "duty_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Društvo se sastoji od stručnog i obrazovnog kadra u oblasti posredovanja kod carinjenja roba"])},
  "other_services_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostale usluge"])},
  "contact_personel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA Šped Kontakt osoblje"])},
  "founded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osnovano"])},
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klijenti"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaposlenih"])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povežite se sa nama"])},
  "transport_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svim našim klijentima pružamo usluge međunarodnog i domaćeg transporta,a specijalizirali smo se i u hitnoj distribuciji robe do kupaca unutar BiH. Pod usluge transporta podrazumijevamo: "])},
  "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenjerski"])},
  "road_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopneni transport"])},
  "maritime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomorski / kontejnerski transport"])},
  "air_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avio transport"])},
  "partial_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcijalni transport"])},
  "intermodal_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermodalni transport"])},
  "consulting_transport_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsultantske usluge vezano za optimalizaciju transportnih troškova i logističkih rješenja"])},
  "transport_additional_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za mnoge od naših klijenata radimo kompletnu express door to door, \"Just in time\", \"Just in sequence\" kao \"Wharehouse on wheel\" uslugu."])},
  "transport_additional_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenutno odradjujemo oko 1500 transportnih zahtjeva mjesečno"])},
  "International_domestic_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Međunarodni / domaći transport  "])},
  "transport_of_dangerous_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport opasnih ADR materija"])},
  "transportation_of_clothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportat tekstila"])},
  "car_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" transport novih auta"])},
  "special_transports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specijalni transport"])},
  "about_us_next_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sljedeće"])},
  "warehouse_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U našim skladišnim prostorima nudimo slijedeće usluge:"])},
  "warehouse_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skladištenje robe sa manipulacijama utovara i istovara"])},
  "warehouse_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visoko regalno skladištenje"])},
  "warehouse_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pakovanje robe"])},
  "warehouse_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozicioniranje"])},
  "warehouse_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortiranje"])},
  "warehouse_li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distribucija"])},
  "warehouse_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi postupci pokriveni su WMS sistemom. "])},
  "warehouse_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takodjer posjedujemo javno carinsko skladište tipa A, u kojem trenutno imamo mnogo korisnika. "])},
  "warehouse_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektno zajedno analiziramo procese i oblikujemo dugoročna rješenja skladišne logistike, u vezi sa potrebama naših kupaca po: "])},
  "warehouse_p4_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["načinu skladištenja"])},
  "warehouse_p4_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svojstvima robe"])},
  "warehouse_p4_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dodatnim radovima"])},
  "warehouse_p4_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potrebnim softverskim  rjesenjima"])},
  "warehouse_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naš Logisticki centar podijeljen je na 2 lokacije: "])},
  "warehouse_p5_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistički centar 1 koji je izgrađen od 2003. godine do 2017. godine"])},
  "warehouse_p5_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupna povrsina  45.000 m2 na kojoj se nalazi : "])},
  "warehouse_p5_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33.000 m2 proizvodno –poslovnog prostora + 2.500 m2 kancelarijskog prostora"])},
  "warehouse_p5_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistički centar 2 koji je će biti izgrađen od 2019. godine do 2023. :   "])},
  "warehouse_p5_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupna površina  48.500m2 na kojoj se nalazi : "])},
  "warehouse_p5_li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.000 m2 izgradjeni proizvodno – poslovni prostor te trenutno u toku  izgradnja još 12.000 m2 proizvodno – poslovnog prostora."])},
  "warehouse_p5_li7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za obje lokacije karakteristična je blizina svih glavnih saobraćajnica, autoputa, aerodroma, glavnog carinskog terminala Sarajevo.  "])},
  "duty_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastupanje klijenata pred carinskim organima u vršenju svih carinskih procedura:"])},
  "duty_p1_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postupak uvoza"])},
  "duty_p1_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postapak izvoza"])},
  "duty_p1_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzit"])},
  "duty_p1_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druge carinske zakonom dozvoljene procedure"])},
  "duty_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za naše klijente obezbjedili smo bankarske garancije u iznosu od 4.000.000,00 KM za sve vrste carinskih procedura."])},
  "duty_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastupljeni smo u svim dijelovima i graničnim prijelazima u BiH sa mrezom svojih poslovnih jedinica i podugovarača."])},
  "duty_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Također nudimo i usluge carinjenja u EU , Turskoj , Velikog Britaniji te zemljama potpisnicama  CEFTE i  EFTE, itd "])},
  "other_services_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsultantske usluge po pitanju osiguranja, pripreme realizacije posla i dr."])},
  "header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nudimo usluge međunarodne i unutarnje špedicije, transport i skladištenje roba"])},
  "header_secondary_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svim našim klijentima pružamo usluge međunarodnog i domaćeg transporta, a specijalizirali smo se i u hitnoj distribuciji robe do kupaca unutar BiH."])},
  "position_haris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvršni direktor za operacije"])},
  "position_nermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktor drušva"])},
  "position_emir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rukovodilac sektora za skladište"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerija"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desila se greška"])},
  "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email uspješno poslan"])},
  "fill_the_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo popunite formu"])},
  "iso_standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA ŠPED posjeduje međunarodni certifikat kvaliteta ISO 9001:2000 za obavljanje djelatnosti iz sfere domaće i međunarodne špedicije i skladištenja, koji uspješno održavamo već 12 godina."])},
  "company_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL kompanije"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompanija"])},
  "done_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrađenih transportnih naloga"])},
  "done_terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urađenih carinskih deklaracija"])},
  "transport_cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevezenih i uskladištenih novih automobila"])},
  "under_roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladišta pod krovom"])},
  "under_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaldišta u izgradnji"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["godine"])},
  "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša nova strategija razvoja je ulazak u partnerske odnose sa renomiranim evropskim i svjetskim kompanijama, a ona se bazira na konceptu izgradnje i najma proizvodnih kapaciteta , najma radne snage i kompletnom logističkom podrškom ( transportna rješenja, procesi carinjenja, softverska podrška..)"])}
}