<template>
  <div class="header">
    <div>
      <h3>{{ $t("duty") }}</h3>
    </div>
  </div>
  <div class="content-news">
    <h5>
      {{ $t("duty_p1") }}
    </h5>

    <ol>
      <li data-aos="fade-right" data-aos-duration="1100">
        {{ $t("duty_p1_li1") }}
      </li>
      <li data-aos="fade-left" data-aos-duration="1100">
        {{ $t("duty_p1_li2") }}
      </li>
      <li data-aos="fade-right" data-aos-duration="1100">
        {{ $t("duty_p1_li3") }}
      </li>
      <li data-aos="fade-left" data-aos-duration="1100">
        {{ $t("duty_p1_li4") }}
      </li>
    </ol>
    <p>
      {{ $t("duty_p2") }}
    </p>
    <p>
      {{ $t("duty_p3") }}
    </p>
    <p>
      {{ $t("duty_p4") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "Duty",
  data() {
    return {
      chips: [{ chip: "#erp" }],
    };
  },
};
</script>
<style lang="scss" scoped>
h3 {
  color: white;
}
.header {
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 567px) {
  .header {
    height: 7vh;
  }
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: list-counter;
  margin-bottom: 26px;
  margin-top: 18px;
}
li {
  padding: 0;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: "adelle-sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #666;
}
ol li {
  position: relative;
  padding-left: 35px;
  min-height: 24px;
  margin-top: 14px;
}
ol li:before {
  content: counter(list-counter, decimal);
  counter-increment: list-counter;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-family: "adelle-sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 100%;
  color: #fff;
  background: #25435b;
}
.padding-3 {
  padding-left: 2%;
}
</style>
