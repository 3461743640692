<template>
  <div class="carousel-wrapper" data-aos="fade-in"></div>
  <div class="container-sm mt-4 mb-4" data-aos="flip-up">
    <GallerySlider :images="images"></GallerySlider>
  </div>
</template>
<script>
import GallerySlider from "@/components/Sliders/GallerySlider.vue";

export default {
  name: "GalleryView",
  components: { GallerySlider },
  data() {
    return {
      images: [
        {
          name: " ",
          url: require("@/assets/images/20.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/30.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/60.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/10.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/40.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/50.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/70.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/80.jpg"),
          text: "",
        },
        {
          name: " ",
          url: require("@/assets/images/90.jpg"),
          text: "",
        },

        {
          name: " ",
          url: require("@/assets/images/mockup-2.jpg"),
          text: "",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scooped>
.carousel-wrapper {
  width: 100%;
  height: 10vh;
}
</style>
