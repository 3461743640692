<template lang="">
  <div class="map-container">
    <iframe
      width="100%"
      height="480"
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86472.07058528645!2d18.268496512507316!3d43.88806044370084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758cb78dd6c91d3%3A0x645592b8a42c5b15!2sASA%20%C5%A0PED%20LOGISTI%C4%8CKI%20CENTAR!5e0!3m2!1shr!2sba!4v1688553741051!5m2!1shr!2sba" >
            frameborder="0"
      scrolling="yes"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
</template>
<script>
import ClosingButton from "./Forms/buttons/ClosingButton.vue";
export default {
  name: "TheLocationMap",
  components: { ClosingButton },
  props: { showMap: { type: Function } },
};
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 0px 0px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.d-flex {
  margin: 0;
  padding: 0;
}

iframe {
  border-radius: 5px;
  margin-bottom: 40px;

  @media screen and (max-width: 567px) {
    height: 400px;
    margin-left: 0% !important;
  }
}
.map-container {
  margin-left: 13%;
  margin-right: 13.6% !important;
}
@media screen and (max-width: 567px) {
  .map-container {
    margin-left: 4.3% !important;
    margin-right: 4.5% !important;
  }
}
</style>
