<template>
  <div class="header"
  data-aos="fade" data-aos-duration="1100">
    <div>
      <h3>{{ $t("warehouse") }}</h3>
    </div>
  </div>
  <div class="content-news">
    <h5>
      {{ $t("warehouse_p1") }}
    </h5>

    <ol>
      <li data-aos="fade-right" data-aos-duration="1100">{{ $t("warehouse_li1") }}</li>
      <li data-aos="fade-left" data-aos-duration="1100">{{ $t("warehouse_li2") }}</li>
      <li data-aos="fade-right" data-aos-duration="1100">{{ $t("warehouse_li3") }}</li>
      <li data-aos="fade-left" data-aos-duration="1100">{{ $t("warehouse_li4") }}</li>
      <li data-aos="fade-right" data-aos-duration="1100">{{ $t("warehouse_li5") }}</li>
      <li data-aos="fade-left" data-aos-duration="1100">{{ $t("warehouse_li6") }}</li>
    </ol>
       <p>
      {{ $t("warehouse_p2") }}
    </p>
    <p>
      {{ $t("warehouse_p3") }}
    </p>
    <p>{{ $t("warehouse_p4") }}</p>
      <ol>
      <li data-aos="fade-right" data-aos-duration="1100">{{ $t("warehouse_p4_li1") }}</li>
      <li data-aos="fade-left" data-aos-duration="1100">{{ $t("warehouse_p4_li2") }}</li>
      <li data-aos="fade-right" data-aos-duration="1100">{{ $t("warehouse_p4_li3") }}</li>
      <li data-aos="fade-left" data-aos-duration="1100">{{ $t("warehouse_p4_li4") }}</li>
    </ol>
 <h5>
      {{ $t("warehouse_p5") }}
    </h5>
<p class="pt-3"><strong>{{ $t("warehouse_p5_li1") }}</strong></p>
<p class="padding-3">{{ $t("warehouse_p5_li2") }}</p>
<p class="padding-3">{{ $t("warehouse_p5_li3") }}</p>
<p><strong>{{ $t("warehouse_p5_li4") }}</strong></p>
<p class="padding-3">{{ $t("warehouse_p5_li5") }}</p>
<p class="padding-3">{{ $t("warehouse_p5_li6") }}</p>
<p>{{ $t("warehouse_p5_li7") }}</p>
  </div>
</template>
<script>
export default {
  name: "Warehouse",
  data() {
    return {
      chips: [
        { chip: "#software-development" },
        { chip: "#erp" },
        { chip: "#design" },
      ],

      content: [
        { p: "software_solutions_p1" },
        { p: "software_solutions_p2" },
        { p: "software_solutions_p3" },
        { p: "software_solutions_p4" },
        { p: "software_solutions_p5" },
        { p: "software_solutions_p6" },
        { p: "software_solutions_p7" },
        { p: "software_solutions_p8" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: white;
}
ol {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: list-counter;
  margin-bottom: 26px;
  margin-top: 18px;
}
li {
  padding: 0;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: "adelle-sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #666;
}
ol li {
  position: relative;
  padding-left: 35px;
  min-height: 24px;
  margin-top: 14px;
}
ol li:before {
  content: counter(list-counter, decimal);
  counter-increment: list-counter;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-family: "adelle-sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 100%;
  color: #fff;
  background: #25435b;
}
.padding-3{
  padding-left: 2%;
}
.header {

  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
@media screen and (max-width: 567px) {
  .header {
      height: 7vh;

  }
  }


</style>
