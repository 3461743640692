<template>
  <div class="nums">
    <h2 class="mb-4">{{ $t("about_us_nums_heading") }}</h2>
    <Line />
    <div class="num-flex">
      <div class="wrap">
        <h2>{{ $t("founded") }}</h2>
        <div class="number">
          <div>{{ roundedValyear }}{{ $t("year") }}</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("clients") }}</h2>
        <div class="number">
          <div>{{ roundedValcli }}+</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("employees") }}</h2>
        <div class="number">
          <div>{{ roundedValEmp }}+</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("done_transport") }}</h2>
        <div class="number">
          <div>{{ roundedValTransport }}+</div>
        </div>
      </div>
    </div>

    <div class="num-flex">
      <div class="wrap">
        <h2>{{ $t("done_terminal") }}</h2>
        <div class="number">
          <div>{{ roundedValTerminal }}</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("transport_cars") }}</h2>
        <div class="number">
          <div>{{ roundedValCars }}+</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("under_roof") }}</h2>
        <div class="number">
          <div>{{ roundedValWarehouse }}m²</div>
        </div>
      </div>
      <div class="wrap">
        <h2>{{ $t("under_construction") }}</h2>
        <div class="number">
          <div>{{ roundedValWarehouseInBuild }}m²</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Line from "@/particles/Line.vue";
import { ref } from "@vue/reactivity";
export default {
  name: "AboutNums",
  setup() {
    const years = ref(0);
    const clients = ref(0);
    const employees = ref(0);
    const cars = ref(0);

    const doneTransports = ref(0);
    const doneTerminals = ref(0);
    const warehouses = ref(0);
    const warehousesInBuild = ref(0);

    const year = ref(2003);
    const client = ref(300);
    const employee = ref(43);
    const doneTransport = ref(18000);

    const doneTerminal = ref(25000);
    const warehouse = ref(49000);
    const warehouseInBuild = ref(12000);
    const car = ref(3250);

    const speed = ref(100);

    const roundedValyear = ref(0);
    const roundedValcli = ref(0);
    const roundedValEmp = ref(0);
    const roundedValTransport = ref(0);
    const roundedValTerminal = ref(0);
    const roundedValCars = ref(0);
    const roundedValWarehouse = ref(0);
    const roundedValWarehouseInBuild = ref(0);

    window.addEventListener("scroll", () => {
      if (document.body.scrollHeight - 1700 < window.scrollY) {
        setInterval(() => {
          if (years.value < year.value) {
            years.value += year.value / speed.value;
            if (years.value > year.value) {
              roundedValyear.value = year.value;
            } else {
              roundedValyear.value = Math.round(years.value);
            }
          }
          if (clients.value < client.value) {
            clients.value += client.value / speed.value;
            roundedValcli.value = Math.round(clients.value);
          }
          if (doneTransports.value < doneTransport.value) {
            doneTransports.value += doneTransport.value / speed.value;
            roundedValTransport.value = Math.round(doneTransports.value);
          }
          if (cars.value < car.value) {
            cars.value += car.value / speed.value;
            roundedValCars.value = Math.round(cars.value);
          }
          if (doneTerminals.value < doneTerminal.value) {
            doneTerminals.value += doneTerminal.value / speed.value;
            roundedValTerminal.value = Math.round(doneTerminals.value);
          }
          if (employees.value < employee.value) {
            employees.value += employee.value / speed.value;
            roundedValEmp.value = Math.round(employees.value);
          }
          if (warehouses.value < warehouse.value) {
            warehouses.value += warehouse.value / speed.value;
            roundedValWarehouse.value = Math.round(warehouses.value);
          }
          if (warehousesInBuild.value < warehouseInBuild.value) {
            warehousesInBuild.value += warehouseInBuild.value / speed.value;
            roundedValWarehouseInBuild.value = Math.round(
              warehousesInBuild.value
            );
          }
        }, 111);
      }
    });
    return {
      roundedValyear,
      roundedValcli,
      roundedValEmp,
      roundedValTransport,
      roundedValTerminal,
      roundedValCars,
      roundedValWarehouse,
      roundedValWarehouseInBuild,
    };
  },
  components: { Line },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 25%;
  overflow: hidden;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  h2 {
    min-height: 2.5em;
    overflow-wrap: break-word;
    text-align: center;
  }
}

.nums {
  padding-top: 5%;
  h2 {
    text-align: center;
  }
  .num-flex {
    display: flex;
    justify-content: space-between;
    margin: 5% 5% 10% 5%;
    gap: 20px;
    font-size: 40px;

    .number {
      background-color: gainsboro;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      justify-self: flex-end;
      vertical-align: bottom;
    }
  }
  .heading-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 5% auto;

    > h3 {
      width: 100%;
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    .num-flex {
      width: 90%;
      margin-top: 10%;
      flex-direction: column;
      font-size: 35px;
    }
    .wrap{width:100%}
  }
}
@media screen and (max-width: 1000px) {
  .num-flex {
    font-size: 75px;
  }
  .number {
    font-size: 30px;
  }
}
</style>
