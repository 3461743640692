<template>
  <div class="header">
    <div>
      <h3>{{ $t("other_services_h4") }}</h3>
    </div>
  </div>
  <div class="content-news">
    <div>
      <h5>{{ $t("other_services_p1") }}</h5>
    </div>
  </div>
</template>
<script>
export default {
  name: "OtherServices",
  data() {
    return {
      chips: [{ chip: "#cloud-based" }, { chip: "#erp" }],
    };
  },
};
</script>
<style lang="scss" scoped>
h3 {
  color: white;
}
.header {
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 567px) {
  .header {
    height: 7vh;
  }
}
</style>
