export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Team"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
  "nav_news_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum brauchen Unternehmen DMS?"])},
  "nav_news_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbasiertes ERP-System"])},
  "nav_news_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Integration"])},
  "nav_news_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisierung"])},
  "nav_news_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ein ERP-System ein kleines Unternehmen beim Wachstum unterstützen?"])},
  "nav_news_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeichen dafür, dass Ihr Unternehmen eine fortschrittlichere Softwarelösung benötigt"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "faq-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbeaktionen"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Präsentation"])},
  "about_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Präsentation"])},
  "widget_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "widget_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Solutions"])},
  "widget_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Digitalisierung Ihrer Geschäftsprozesse mit dem ERP-Programm von Forspace möchten wir Sie in die Lage versetzen, das Unternehmen effizienter zu führen und die Geschäftsaktivitäten in jedem Moment kontinuierlich zu überwachen, zu planen und zu steuern und dabei Fehler zu vermeiden, die sonst zu Komplikationen führen würden."])},
  "widget_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für alle Fragen anrufen"])},
  "widget_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33 774 873"])},
  "widget_icon_one_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten"])},
  "widget_icon_two_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Projekte"])},
  "widget_icon_three_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre der Erfahrung"])},
  "recension_primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezensionen"])},
  "recension_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was unsere Kunden über uns sagen"])},
  "recension_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind stolz auf alle unsere Kunden und bauen partnerschaftliche Beziehungen zur gegenseitigen Zufriedenheit auf. Wenn einige unserer Kunden noch nicht in der Liste aufgeführt sind, bedeutet das nicht, dass wir sie weniger schätzen oder dass sie weniger wichtig für uns sind."])},
  "recension_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie noch Fragen?"])},
  "recension_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns."])},
  "about_us_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "about_us_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti, atque amet architecto vitae sapiente explicabo."])},
  "about_us_news_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "about_us_news_link_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP einzigartige All-in-One Cloud-Softwarelösung zur Standardisierung und Digitalisierung von Geschäftsprozessen"])},
  "about_us_news_link_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum brauchen Unternehmen ein DMS?"])},
  "about_us_news_link_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Unternehmen zu führen kann in vielerlei Hinsicht stressig sein, und eine besondere Herausforderung ist dabei die Art und Weise, wie das Unternehmen Dokumente verwaltet."])},
  "about_us_news_link_heading_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum ist ein webbasiertes Geschäftsprogramm die richtige Wahl?"])},
  "about_us_news_right_flex_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcher Unternehmertyp sind Sie?"])},
  "about_us_news_right_flex_one_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmertum"])},
  "about_us_news_right_flex_one_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Unternehmer wird in der Regel als Innovator betrachtet, der neue Ideen, Waren, Dienstleistungen und/oder Geschäftsabläufe hervorbringt."])},
  "about_us_news_right_flex_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeichen dafür, dass Ihr Unternehmen eine fortschrittlichere Softwarelösung benötigt"])},
  "about_us_news_right_flex_two_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software-Lösungen"])},
  "about_us_news_right_flex_two_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Unternehmen versuchen, ihre Gesamtleistung durch die Einführung von ERP-Software zu verbessern."])},
  "about_us_news_right_flex_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ERP einem kleinen Unternehmen beim Wachstum helfen?"])},
  "about_us_news_right_flex_three_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-System"])},
  "about_us_news_right_flex_three_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-Resource-Planning-System, genannt ERP, verwaltet alle Geschäftsbereiche, einschließlich Produktion, Beschaffung, Verkauf, Vertrieb, Buchhaltung, Personalwesen, Management-System..."])},
  "about_us_news_right_flex_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie macht die digitale Transformation die Arbeit von Buchhaltern einfacher und schneller?"])},
  "about_us_news_right_flex_four_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale Transformation"])},
  "about_us_news_right_flex_four_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufstätige in allen Branchen stehen vor großen Veränderungen, von denen viele durch neue Technologien angetrieben werden."])},
  "about_us_next_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "about_us_next_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau auf den Raum"])},
  "about_us_next_overlay_left_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteile der digitalen Transformation"])},
  "about_us_next_overlay_left_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beschleunigung der digitalen Welt erfordert die Anpassung von Organisationen, weshalb die meisten Unternehmen weltweit an der digitalen Transformation arbeiten..."])},
  "about_us_next_overlay_right_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist die API?"])},
  "about_us_next_overlay_right_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Begriff API wird in der Webentwicklung häufig verwendet. Bei Aktualisierungen von Betriebssystemen, Webbrowsern und Anwendungen werden häufig neue APIs für Entwickler angekündigt. Was also ist eine API und wie können Entwickler..."])},
  "about_us_choose_small_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum uns wählen"])},
  "about_us_choose_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäfte aus einer Hand"])},
  "about_us_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In unserem Unternehmen"])},
  "about_us_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Forspace-Team bietet uneingeschränkte Unterstützung in allen Phasen der Nutzung, und wir sind besonders stolz auf unseren Kundendienst, denn wenn Sie diese Software akzeptieren, können Sie auf unser ständiges Engagement und unseren Kontakt zählen. Wir sind die erste einheimische Marke auf dem ERP-Softwaremarkt mit einem Zeichen deutscher Technologie, die es ermöglicht, die Verwendung von schriftlicher Dokumentation zu reduzieren, Zeit und Geld zu sparen, und eine vollständig angepasste und verständliche Softwarelösung für den Endbenutzer darstellt."])},
  "about_check_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt die einfache Integration mit anderen Lösungen."])},
  "about_check_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildet reale Prozesse auf digitale Prozesse ab."])},
  "about_check_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrales Prozessmanagement."])},
  "about_us_video_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "about_us_video_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich unser kurzes Video an"])},
  "about_us_nums_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Ende des Jahres 2022 lauten unsere Indikatoren:"])},
  "about_us_nums_established": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etabliert"])},
  "about_us_nums_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "about_us_nums_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschäftigt"])},
  "about_us_client_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um die Automatisierung der Buchhaltung und die vollständige Anpassung an unsere Bedürfnisse geht, ist das Team von Forspace Solutions die erste Wahl. Die Konkurrenz ist groß, aber Forspace macht es möglich.\" "])},
  "about_us_client_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um Buchhaltungsautomatisierung und vollständige Anpassung an unsere Bedürfnisse geht, suchen Sie nicht weiter als das Team von Forspace Solutions. Die Konkurrenz ist groß, aber mit Forspace ist es machbar.\" "])},
  "about_us_client_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um Buchhaltungsautomatisierung und vollständige Anpassung an unsere Bedürfnisse geht, suchen Sie nicht weiter als das Team von Forspace Solutions. Die Konkurrenz ist groß, aber mit Forspace ist es machbar.\" "])},
  "about_us_reserve_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie einen Termin"])},
  "about_us_reserve_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerne können Sie einen Termin mit unserem Team vereinbaren, um mehr über unsere Produkte oder über Prozesse in Ihrem Unternehmen zu erfahren, die eine Softwarelösung und Beratung benötigen. Vielleicht haben wir die perfekte Lösung für Sie, basierend auf unseren Best Practices und Erfahrungen."])},
  "products_purchase_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
  "products_purchase_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mit dem Forspace Beschaffungsmodul können Sie ganz einfach alles bestellen, was Sie für Ihr Tagesgeschäft benötigen. Das System kümmert sich um die Korrektheit des Prozesses: Wer sollte die Bestellung genehmigen? (ein automatischer Workflow für die Genehmigung von Budgetüberschreitungen durch die zuständige Person ist hier ebenfalls aktiviert, so dass der Prozess nicht ins Stocken gerät). Erlaubt die Mehrfachgenehmigung von vordefinierten Personen."])},
  "products_sales_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großhandel"])},
  "products_sales_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelhandel - Großhandel"])},
  "products_sales_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser standardisiertes Großhandels- oder Einzelhandelsmodul wird alle Ihre Geschäftsanforderungen erfüllen. Das Einzelhandelsmodul ist zusätzlich an die Arbeit mit POS-Geräten und alle anderen gesetzlichen Anforderungen und Verpflichtungen angepasst. Kontaktieren Sie uns für weitere Details."])},
  "products_production_header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "products_production_header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie bekommt man die Produktion in den Griff?"])},
  "products_production_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produktionsmodul ist die größte Herausforderung bei der Einführung eines ERP-Systems. Damit einher geht die Notwendigkeit eines kulturellen Wandels innerhalb der Organisation. Die Mitarbeiter müssen sich auf die verschiedenen Änderungen und Prozessoptimierungen einstellen, die auf sie zukommen. ERP-Systeme basieren auf bewährten Praktiken in verschiedenen Branchen, aber einmal ist die Software nicht sehr gut an die Prozesse in einem bestimmten Unternehmen angepasst. Und in diesen Fällen haben wir eine Lösung durch maßgeschneiderte Anpassungen, die wir schnell und effizient implementieren. Das Forspace-Team steht Ihnen nicht nur während der Implementierung zur Seite, sondern wir arbeiten auch danach mit Ihnen zusammen und vermitteln Ihnen Wissen über Verfahren und Methoden, mit denen Sie das EPR-System weiterhin erfolgreich nutzen können."])},
  "products_warehouse_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "products_warehouse_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentraler Punkt der logistischen Aktivitäten"])},
  "products_warehouse_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehousing ist die Kunst der Bewegung und Aufbewahrung von Materialien in Lager und Produktion. Mit diesem Modul überwachen wir die Bewegung von Materialien, Halbfertigprodukten und Produkten auf transparente Weise und in Übereinstimmung mit dem Plan und den definierten Prozessen. Es umfasst sowohl den physischen Raum und die Platzierung der Materialien als auch die Softwareüberwachung aller Aktivitäten und Transaktionen."])},
  "products_warehouse_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige der wichtigsten Funktionalitäten sind:"])},
  "products_warehouse_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Die Möglichkeit, ein oder mehrere Lager innerhalb des Unternehmens zu erstellen, mit mehreren Lagerebenen."])},
  "products_warehouse_text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Definition von Lagerzellen durch die Art der Artikel, die in diesen Zellen untergebracht werden (Zellen für Materialien, Waren, Ersatzteile und ähnliches)"])},
  "products_warehouse_text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Empfang von Artikeln aus dem Transit, von Scannern oder manuell, in die entsprechenden Lagerzellen, mit vollständiger Verfolgung jeder Transaktion über den Barcode des Artikels."])},
  "products_warehouse_text_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Automatische Erstellung von Bestellungen für eingegangene Artikel (Verbindung von Eingangsrechnungen und Lieferscheinen). - Druck von Etiketten und Barcodes der Artikel."])},
  "products_warehouse_text_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Verschieben von Artikeln zwischen Lagerzellen, mit begleitenden Berichten. Einsicht in die Lagerbedingungen mit Informationen wie: Identität, Beschreibung, Menge, Preis, Lieferant, Charge, Lagerzelle und ähnliches."])},
  "products_warehouse_text_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Inventar. Einblick in die Historie der Bewegungen von Lagerartikeln mit transparenter Darstellung aller notwendigen Informationen."])},
  "products_dms_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenten-Management-System (DMS)"])},
  "products_dms_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erschwinglich und praktisch"])},
  "products_dms_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokumentenmanagementsystem (DMS) dient der Verwaltung aller Dokumente im Unternehmen. Es ermöglicht die Speicherung aller Arten von Dokumenten nach dem gleichen Prinzip, wie wir physische Dokumente speichern. Wenn wir physische Dokumente erhalten und sie in Ordnern klassifizieren, tun wir das Gleiche im DMS mit elektronischen Ordnern ."])},
  "products_dms_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist nur ein kleiner Teil der Funktionalität unseres DMS-Systems, das vollständig in den Finanzfluss der von uns aufbewahrten Dokumente integriert ist, so dass wir alle wichtigen Daten (Kunden, Lieferanten, Beträge, Dokumentennummern usw.) in das System eingeben und sie weiterhin für die Buchhaltung und das Berichtswesen verwenden."])},
  "products_row_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe von Eingangsrechnungen und Start des Genehmigungsprozesses durch einen vordefinierten Workflow"])},
  "products_row_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstellen von Ausgangsrechnungen und Vorbereitung der erforderlichen Dokumente"])},
  "products_row_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit, wiederkehrende Ausgangsrechnungen zu klonen"])},
  "products_row_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit, Eingangsrechnungen mit genehmigten Aufträgen zu verknüpfen."])},
  "products_row_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Überprüfung des Budgetverbrauchs"])},
  "products_row_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration mit POS-Terminals"])},
  "products_row_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenreservierung (Lagerein- und -ausgangstransaktionen)"])},
  "products_row_eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsverfolgung - detaillierte Informationen über den Warenfluss auf der Grundlage von Rechnungspositionen"])},
  "products_row_nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchführung auf der Grundlage vordefinierter Buchungsprofile"])},
  "products_finance_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparent und genau"])},
  "products_finance_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budgetanforderung"])},
  "products_finance_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Finanz- und Buchhaltungsmodul ist auf der Grundlage der weltweit besten Praktiken aufgebaut, bei denen Sie planen und die Ausgaben entsprechend dem Plan tätigen. Vergleichen Sie außerdem das Geplante mit dem Realisierten. Forspace sorgt dafür, dass alles genau und transparent ist."])},
  "products_finance_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. die Verwaltung des Unternehmensbudgets nach Ausgabenarten"])},
  "products_finance_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. die Verwaltung des Budgets des Unternehmens nach Kostenstellen und Kostenarten"])},
  "products_finance_workflow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeiten mit Fluss oder Arbeiten nach Prozessfluss"])},
  "products_finance_workflow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehende Dokumente - Schritte bei der Rechnungsbearbeitung"])},
  "products_finance_assets_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagevermögen"])},
  "products_finance_assets_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschaffung, Nutzung und Abschreibung von Anlagegütern"])},
  "products_paydesk_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassierer"])},
  "products_paydesk_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahme und Auszahlung"])},
  "products_paydesk_header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles, was Sie brauchen, um Ihre Kasse genau, transparent und im Einklang mit Genehmigungen und Budget zu verwalten."])},
  "products_paydesk_01_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassen und Bestätigen des Geldeingangs nach einem vordefinierten Workflow"])},
  "products_paydesk_02_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassen und Prüfen von Barzahlungen nach einem vordefinierten Workflow"])},
  "products_paydesk_03_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bareingangs- und Zahlungsberechnung"])},
  "products_paydesk_04_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasse einsehen und Beleg erzeugen"])},
  "Produkte_paydesk_05_Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassenbucheinträge ansehen und Belege generieren"])},
  "products_paydesk_06_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung der täglichen Transaktionen in das Hauptbuch auf der Grundlage der der Kasse zugeordneten Konten"])},
  "products_bank_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauszüge"])},
  "products_bank_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Verarbeitung und Buchung"])},
  "products_bank_1_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import in verschiedenen Formaten"])},
  "products_bank_1_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import von elektronischen Kontoauszügen in verschiedenen Formaten"])},
  "products_bank_1_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronisches Bankformat"])},
  "products_bank_1_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Konvertierungsprogramme für spezielle elektronische Bankformate"])},
  "products_bank_1_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsablauf"])},
  "products_bank_1_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung und Verarbeitung von Kontoauszügen"])},
  "products_bank_2_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Journalbuchungen"])},
  "products_bank_2_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Journalbuchungen aus Bankauszug"])},
  "products_bank_2_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerregister"])},
  "products_bank_2_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Zugang zu allen Informationen über Partnerbanken und Bankkonten"])},
  "products_bank_2_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])},
  "products_bank_2_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fähigkeit zur Erkennung von Kontoauszugspositionstypen und zugehörigen Konten"])},
  "products_tax_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEHRWERTSTEUER-RÜCKERSTATTUNG"])},
  "products_tax_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Bücher der ausgestellten und empfangenen Rechnungen"])},
  "products_tax_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOKUMENT ZUR MEHRWERTSTEUERERSTATTUNG"])},
  "products_tax_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Erstellung von Mehrwertsteuererstattungsdokumenten"])},
  "products_tax_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT POSTING"])},
  "products_tax_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung der Mehrwertsteuer"])},
  "products_tax_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTROLLBERICHTE"])},
  "products_tax_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücher der ausgestellten und empfangenen Rechnungen"])},
  "products_hr_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalwesen und Lohnbuchhaltung"])},
  "products_hr_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Hilfe des Forspace HR-Moduls und des PAYROLL CALCULATION-Moduls hat Ihr Unternehmen die Möglichkeit, Aufzeichnungen über Mitarbeiter nach den modernsten Standards zu führen. Angefangen von anpassbaren Daten in der Personalakte, dem beruflichen Werdegang und ähnlichem bis hin zu einem automatisierten Modul zur Vertragserstellung, das die Arbeit Ihrer HR-Mitarbeiter erheblich erleichtert. Für das Gehaltsabrechnungsmodul haben Sie zahlreiche Vorteile und Automatisierungen sowie regelmäßige Einstellungen, die den Standards der Gesetzgebung und GDPR entsprechen."])},
  "hr_heading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register der Mitarbeiter"])},
  "hr_heading_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufzeichnungen über Familienmitglieder, Nichtansässige/Ausländer, Arbeitsgenehmigungen, verschiedene Bescheinigungen"])},
  "hr_heading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter für die Gehaltsberechnung"])},
  "hr_heading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überwachung verschiedener Abwesenheiten"])},
  "hr_heading_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassung der Arbeitszeiten"])},
  "hr_heading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheitsinformationen für Mitarbeiter"])},
  "hr_heading_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufzeichnungen über Verschuldung, Schuldentilgung, Unterhaltszahlungen"])},
  "hr_heading_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Definition von Berechnungseinstellungen - Gehaltsberechnungsprofil"])},
  "hr_heading_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschiedene Berechnungsarten je nach Art der Beschäftigung"])},
  "hr_heading_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschiedliche Methoden für Berechnungsarten je nach Art der Einrichtung"])},
  "hr_heading_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschiedliche Methoden zur Berechnung des Grundgehalts"])},
  "hr_heading_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten für mehrere Mitarbeiter gleichzeitig mit Hilfe von Importern eingeben, bearbeiten oder löschen"])},
  "hr_heading_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Möglichkeit der Neuberechnung für einen einzelnen Mitarbeiter oder für alle Mitarbeiter"])},
  "hr_heading_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von kurz- und langfristigen Darlehen und anderen Abzügen"])},
  "hr_heading_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von zusätzlichen Zahlungen (Prämien, Zuschüsse für warme Mahlzeiten und Transport, Erhöhung des Dienstalters)"])},
  "hr_heading_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit des Drucks von Zahlscheinen im PDF-Format oder des Versands per E-Mail"])},
  "hr_heading_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung im Hauptbuch"])},
  "hr_heading_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerung der gleichzeitigen Verwendung und Bearbeitung von Daten in Mitarbeiterdateien während der Vorbereitung der Lohnabrechnung"])},
  "hr_heading_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente zur elektronischen Bezahlung"])},
  "services_slider_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung von Web- und Desktop-Anwendungen"])},
  "services_slider_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web- und Desktop-Anwendungen"])},
  "services_slider_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichtere Geschäftsprozesse mit mobilen Anwendungen"])},
  "services_slider_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Anwendungen"])},
  "services_slider_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bessere Geschäftslösungen mit API-Integration"])},
  "services_slider_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Integration"])},
  "services_container_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklerdienste und Entwicklung"])},
  "services_icon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web- und Desktop-Anwendungen"])},
  "services_icon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Apps"])},
  "services_icon_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api-Integration"])},
  "services_grid_div4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider Anwendungen für Zahlungssysteme im Bereich eCommerce und Integration in die gesamte Prozesskette"])},
  "services_grid_div5_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenspezifische Lösungen"])},
  "services_grid_div5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind Experten in der Umsetzung verschiedener geschäftlicher Bedürfnisse/Ideen unserer Kunden in digitale Produkte zur Verbesserung ihrer Geschäftsprozesse, Entwicklung und digitalen Transformation."])},
  "contact_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie uns eine Nachricht"])},
  "contact_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
  "contact_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail"])},
  "contact_form_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
  "contact_form_nameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Namen ein"])},
  "contact_form_emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail ein"])},
  "contact_form_messageInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Nachricht ein"])},
  "contact_form_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Map"])},
  "footer_forspace_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Produkte"])},
  "footer_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accounting"])},
  "footer_controlling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["controlling"])},
  "footer_human_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalwesen"])},
  "footer_all_in_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all-in-one erp solution"])},
  "footer_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Dienstleistungen"])},
  "footer_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "footer_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulevar Meše Selimovića 16"])},
  "footer_address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnien und Herzegowina"])},
  "footer_address_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33/770-949"])},
  "footer_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Forspace - Alle Rechte vorbehalten"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])},
  "services-_icon_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux, Unix, macOS, Windows"])},
  "services-_icon_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt auf Android- und iOS-Betriebssystemen"])},
  "services-_icon_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf dem Server"])},
  "why_companies_need_dms_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Führung eines Unternehmens kann in vielerlei Hinsicht anstrengend sein, und eine besondere Herausforderung ist dabei die Art und Weise, wie das Unternehmen Dokumente verwaltet. Zum Glück gibt es heute Softwarelösungen, die sich um die Dokumentation des Unternehmens kümmern."])},
  "why_companies_need_dms_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die physische Dokumentation gehört der Vergangenheit an"])},
  "Warum_Unternehmen_brauchen_dms_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für manche Aufgaben, z. B. in der Buchhaltung und in Rechtsangelegenheiten, ist diese Art von Software ein echter Lebensretter. Anstatt für jede Kleinigkeit einen Stapel Papier zu durchforsten, können Sie Ihre Dokumente einfach digitalisieren und sich darauf verlassen, dass die führende OCR-Suche Ihnen hilft, alle Ihre Dateien zu finden, selbst wenn sie in schreibgeschützten Formaten wie Bildern, Scans oder PDFs vorliegen."])},
  "Warum_Unternehmen_brauchen_dms_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten lassen sich leicht weitergeben"])},
  "Warum_Unternehmen_brauchen_dms_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kommunikation zwischen Mitarbeitern sollte so einfach sein, als ob sie nebeneinander sitzen würden. Mit dem Forspace DMS-System können Sie alle Informationen und Dokumente schnell und einfach mit Ihren Kollegen teilen, so dass Sie keine Zeit mit dem Schreiben von E-Mails oder Anrufen verbringen müssen."])},
  "why_companies_need_dms_h6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhte Produktivität am Arbeitsplatz"])},
  "why_companies_need_dms_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Unternehmen seine Dokumente physisch aufbewahrt, wird dabei viel Zeit und Arbeitskraft verschwendet. Die Speicherung und gemeinsame Nutzung von Dokumenten wird zu einem mühsamen Prozess, der viel unnötigen Aufwand erfordert. Dies wiederum verringert die Gesamtproduktivität der Mitarbeiter. Die physische Suche nach einem Dokument und die anschließende Bereitstellung auf dem Schreibtisch des Mitarbeiters, der es benötigt, ist eine Verschwendung von Zeit und Ressourcen. Ein Dokumentenmanagementsystem vereinfacht das Auffinden von Dokumenten, da es den Mitarbeitern ermöglicht, über eine einfache Suche von überall her auf die benötigten Dokumente zuzugreifen. Mit einem DMS können Sie eine Datei innerhalb von Sekunden an alle Mitarbeiter weitergeben, die sie benötigen. Ihre Mitarbeiter müssen keine Zeit mehr mit der physischen Suche nach Dateien verbringen und können diese Zeit stattdessen sinnvoller nutzen und ihre Produktivität steigern."])},
  "warum_unternehmen_brauchen_dms_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Einsatz eines Dokumentenmanagementsystems spart auch eine Menge Geld. Auf den ersten Blick mag es wie eine Investition erscheinen, weil Sie eine Software kaufen müssen, aber die Rendite ist ebenfalls erheblich. Erstens können Sie eine Menge Papier und ähnliche Produkte einsparen. Außerdem werden die Mitarbeiter durch die Abschaffung des traditionellen Dateimanagementsystems produktiver, was sich in einer verbesserten Effizienz und höheren Gewinnen niederschlägt."])},
  "why_companies_need_dms_h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP verfügt über ein integriertes Dokumentenmanagementsystem (DMS), das die Speicherung, Archivierung und Bearbeitung aller Arten von Dokumenten ermöglicht. Forspace DMS ermöglicht die Verbindung zwischen Benutzern, vollständig harmonisierte Daten und einen Überblick über das gesamte Unternehmen."])},
  "why_web_based_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-basierte Anwendungen haben sich in den letzten Jahren erheblich weiterentwickelt und werden zunehmend genutzt, da sie Unternehmen in verschiedenen Branchen die Erstellung, Bearbeitung, den Zugriff und die gemeinsame Nutzung wichtiger Geschäftsdaten ermöglichen. Mit dem Fortschritt der Unternehmen wird das Cloud Computing zu einem dominierenden Akteur für Geschäftsdaten in Bezug auf größere Sicherheit, schnellere Implementierung und Upgrades sowie die Reduzierung der Gesamtkosten. Immer mehr Unternehmen verlagern ihr ERP-System in die Cloud, und im Folgenden erfahren Sie, warum das so ist."])},
  "why_web_based_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrigere Einrichtungs- und Implementierungskosten"])},
  "why_web_based_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-basierte ERP-Systeme haben im Gegensatz zum traditionellen, gehosteten Modell niedrige Implementierungskosten. Eine Vor-Ort-Implementierung erfordert in der Regel erhebliche Investitionen in Hardware und Fachpersonal. Bei Cloud-basierter Software erübrigt sich der Einsatz zusätzlicher Hardware und Mitarbeiter durch das Off-Site-Hosting und die Expertise des Anbieters. Die Senkung der Gesamtkosten ist der erste und wichtigste Grund, warum Unternehmen auf cloudbasiertes ERP umsteigen."])},
  "why_web_based_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Datenspeicherung"])},
  "why_web_based_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Server vor Ort voll sind, muss zusätzliche Hardware angeschafft werden. Mit cloudbasiertem ERP können Unternehmen den Speicherplatz je nach Bedarf schnell erweitern oder verringern."])},
  "why_web_based_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichtere Datensicherung und -wiederherstellung"])},
  "why_web_based_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premises-ERP beinhaltet einen komplexen Datensicherungsprozess und erfordert in der Regel einen Wartungsplan für SQL Server sowie andere Produkte/Dienste. Bei Cloud-basiertem ERP wird täglich ein regelmäßiges Backup des Systems erstellt, das den Nutzern zur Verfügung steht, mit der Möglichkeit, Sicherungskopien der Nutzer zu erstellen."])},
  "why_web_based_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhte Sicherheit und Skalierbarkeit"])},
  "why_web_based_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokale Umgebungen sind in der Regel weniger sicher und erfordern eine teure zusätzliche Überwachung und einen eigenen IT-Administrator. Die Rechenzentren von Forspace sind sicher, zertifiziert und entsprechen den internationalen Standards. Die Skalierbarkeit ist das größte Problem bei lokalen ERP-Systemen, da sie an die vorhandenen Hardwarekapazitäten des Unternehmens gebunden sind. Cloud-basierte ERP-Systeme bieten Skalierbarkeitsoptionen, z. B. das Hinzufügen neuer Lizenzen und weiterer Benutzer, wenn das Unternehmen neue Mitarbeiter einstellt. Darüber hinaus bieten sie zusätzlichen Speicherplatz und die Möglichkeit, neue Produktionsprozesse und -anlagen zu integrieren."])},
  "why_web_based_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Datenplattform"])},
  "what_is_api_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Begriff API wird in der Webentwicklung häufig verwendet. Bei Aktualisierungen von Betriebssystemen, Webbrowsern und Anwendungen werden häufig neue APIs für Entwickler angekündigt. Was also ist eine API und wie verwenden Entwickler sie?"])},
  "what_is_api_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Zusammenarbeit"])},
  "what_is_api_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ist ein Akronym für Application Programming Interface - ein Softwarevermittler, der es zwei Anwendungen ermöglicht, miteinander zu kommunizieren. Jedes Mal, wenn Sie eine Anwendung wie Facebook nutzen, eine Nachricht senden oder das Wetter auf Ihrem Telefon abfragen, verwenden Sie eine API. Zweck der API-Spezifikation ist die Standardisierung des Datenaustauschs zwischen Webdiensten. In diesem Fall bedeutet Standardisierung die Fähigkeit verschiedener Systeme, die in unterschiedlichen Programmiersprachen geschrieben sind und auf unterschiedlichen Betriebssystemen laufen oder unterschiedliche Technologien verwenden, nahtlos miteinander zu kommunizieren."])},
  "what_is_api_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erleichterte Innovationen"])},
  "what_is_api_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs sitzen zwischen der Anwendung und dem Webserver und fungieren als Zwischenschicht, die den Datentransfer zwischen den Systemen abwickelt."])},
  "what_is_api_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten-Monetarisierung"])},
  "what_is_api_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funktionsweise der API kann in mehreren Schritten erklärt werden:"])},
  "what_is_api_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Sicherheit"])},
  "what_is_api_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Client-Anwendung initiiert einen API-Aufruf, um Informationen abzurufen; dieser Vorgang wird als Anfrage bezeichnet. Diese Anfrage wird von der Anwendung über den Uniform Resource Identifier (URI) der API an den Webserver weitergeleitet und enthält einen Anfragesubjekt, eine Kopfzeile und manchmal eine Erklärung der Anfrage."])},
  "what_is_api_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Datenplattform"])},
  "what_is_api_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Empfang einer gültigen Anfrage ruft die API ein externes Programm oder einen Webserver auf."])},
  "what_is_api_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server sendet eine Antwort mit den angeforderten Informationen an die API."])},
  "what_is_api_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die API übergibt Daten an die Anwendung, die die Anfrage gestellt hat"])},
  "what_is_api_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige der wichtigsten Vorteile der API sind:"])},
  "what_is_api_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs ermöglichen die Integration von zwei Plattformen, so dass diese nahtlos miteinander kommunizieren können. Durch diese Integration können Unternehmen Arbeitsabläufe automatisieren und die Zusammenarbeit am Arbeitsplatz verbessern. Ohne APIs würde es vielen Unternehmen an Konnektivität fehlen und sie würden unter Informationssilos leiden, die Produktivität und Leistung gefährden."])},
  "what_is_api_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs bieten Flexibilität und ermöglichen es Unternehmen, sich mit neuen Geschäftspartnern zu verbinden, neue Dienstleistungen in einem bestehenden Markt anzubieten und letztlich neue Märkte zu erschließen, die enorme Erträge generieren und die digitale Transformation vorantreiben können."])},
  "what_is_api_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele Unternehmen entscheiden sich dafür, APIs zumindest anfangs kostenlos anzubieten, damit sie ein Entwicklerpublikum um ihre Marke herum aufbauen und Beziehungen zu potenziellen Geschäftspartnern aufbauen können. Wenn die API jedoch Zugang zu wertvollen digitalen Assets gewährt, kann sie durch den Verkauf des Zugangs monetarisiert werden (dies wird als API-Wirtschaft bezeichnet)."])},
  "what_is_api_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie bereits erwähnt, schaffen APIs eine zusätzliche Schutzschicht zwischen Ihren Daten und dem Server. Entwickler können die API-Sicherheit durch die Verwendung von Token, Signaturen und Transport Layer Security (TLS)-Verschlüsselung weiter stärken, indem sie ein API-Gateway für die Verwaltung und Authentifizierung des Datenverkehrs implementieren und eine effiziente API-Verwaltung praktizieren."])},
  "digita_trans_adv_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konzept der digitalen Transformation beinhaltet den strategischen Einsatz digitaler Technologien, der Ihr Unternehmen vereinfacht und beschleunigt - vom Geschäftsmodell bis hin zu Ihren internen Prozessen. Ziel der Digitalisierung Ihres Unternehmens ist es, die Vorteile der digitalen Transformation zu nutzen, z. B. die Steigerung des Umsatzes, die Verbesserung des Nutzererlebnisses und die Erlangung eines Wettbewerbsvorteils."])},
  "digita_trans_adv_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Effizienz"])},
  "digita_trans_adv_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einer der größten Vorteile der Implementierung eines ERP-Systems in Ihrem Unternehmen ist die dramatische Verbesserung der Effizienz. Die Art und Weise, wie Ihre Mitarbeiter von Abteilung zu Abteilung kommunizieren, der kontinuierliche Datenfluss im gesamten Unternehmen, der reibungslose Übergang von Phase zu Phase während der Lebensdauer des Benutzers - all diese Vorteile tragen zu einem effizienteren Geschäftsprozess bei, der Zeit, Geld und Ressourcen sparen kann."])},
  "digita_trans_adv_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteneinsparungen"])},
  "digita_trans_adv_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der richtigen ERP-Software gibt es viele Möglichkeiten, Kosten zu sparen, insbesondere bei den betrieblichen Abläufen. Zum Beispiel hat ein Fertigungsunternehmen einen besseren Einblick in die benötigten Mengen und Zeitpläne von Rohstoffen. Durch eine strategische, datengesteuerte Bestellung von Rohstoffen sind Einsparungen unausweichlich."])},
  "digita_trans_adv_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinnwachstum"])},
  "digita_trans_adv_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Minimierung der Kosten ist es einfacher, den Gewinn zu steigern. Die Maximierung von Chancen durch die Identifizierung von Lücken in den aktuellen Geschäftsprozessen ist für den Fortschritt des Unternehmens von wesentlicher Bedeutung. Durch die Verbesserung der Transparenz in verschiedenen Aspekten des Geschäfts können Mitarbeiter und Management ihre Best Practices verbessern und den Umsatz steigern."])},
  "digita_trans_adv_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserung des Wettbewerbsvorteils"])},
  "digita_trans_adv_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indem Sie die Infrastruktur Ihres Unternehmens verbessern, steigern Sie Ihren Vorteil auf dem Markt. Am besten planen Sie im Voraus die Merkmale und Funktionen, die Ihr neues System benötigt, um Ihr Unternehmen zu stärken. Beschäftigen Sie sich mit dem, was Sie gut können, und finden Sie die Teile des Unternehmens, die Sie verbessern können, und arbeiten Sie daran. Die Merkmale und Funktionen der Software, für die Sie sich entscheiden, sollten Ihre Bedürfnisse widerspiegeln und Ihre Unterscheidungsmerkmale verbessern."])},
  "digita_trans_adv_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhte Agilität"])},
  "digita_trans_adv_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit zunehmender Automatisierung und verbesserter Produktivität steigt auch die Agilität. Nur durch die Entwicklung einer digitalen Strategie können Unternehmen in Projekten und operativen Bereichen wirklich agil werden. Der Einsatz digitaler Technologien, die Ihnen die Arbeit abnehmen, sorgt dafür, dass die Teams in Ihrem Unternehmen agil werden. Dies beschleunigt Ergebnisse, Informationen, Berichterstattung und Entscheidungsfindung."])},
  "digita_trans_adv_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server sendet eine Antwort an die API mit den angeforderten Informationen."])},
  "entrepeneur_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Prozess der Gründung eines Unternehmens wird als Unternehmertum bezeichnet. Ein Unternehmer gilt in der Regel als Innovator, der neue Ideen, Waren, Dienstleistungen und/oder Geschäftsverfahren hervorbringt."])},
  "entrepeneur_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovatoren"])},
  "entrepeneur_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovatoren sind die Art von Unternehmern, die völlig neue Ideen entwickeln und diese in lebensfähige Unternehmen umsetzen. In den meisten Fällen ändern diese Unternehmer die Art und Weise, wie Menschen denken und Dinge anders machen. Solche Unternehmer sind in der Regel extrem leidenschaftlich und besessen, weil sie ihre Motivation aus der Einzigartigkeit ihrer innovativen Ideen beziehen. Innovative Unternehmer finden auch neue Wege, um ihre Produkte zu vermarkten, indem sie Strategien zur Produktdifferenzierung wählen, mit denen sich ihr Unternehmen von der Masse abhebt. Manchmal geht es nicht nur darum, aus der Masse herauszustechen, sondern tatsächlich eine neue Masse zu schaffen"])},
  "entrepeneur_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hustler"])},
  "entrepeneur_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Gegensatz zu den Innovatoren, deren Visionen nur Benzin in ihrem Motor sind, arbeiten Hustler härter und sind bereit, sich die Hände schmutzig zu machen. Diese Art von Unternehmern konzentriert sich anfangs auf kleine Ziele mit dem Wunsch, in der Zukunft größere Ziele zu erreichen. Hustler sind durch ihre Träume motiviert und arbeiten extrem hart, um sie zu verwirklichen. Allerdings dauert die Verwirklichung ihrer Träume viel länger als bei den meisten anderen Unternehmertypen."])},
  "entrepeneur_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachahmer"])},
  "entrepeneur_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachahmer sind die Unternehmertypen, die bestimmte Geschäftsideen kopieren und sie verbessern. Sie sind immer auf der Suche nach Möglichkeiten, ein bestimmtes Produkt zu verbessern, um sich einen Vorteil auf dem Markt zu verschaffen. Nachahmer sind zum Teil Innovatoren und zum Teil Jäger, die sich nicht an die von anderen gesetzten Bedingungen halten und sehr selbstbewusst sind."])},
  "entrepeneur_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecker"])},
  "entrepeneur_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch wenn sie eine Idee haben, nehmen sich Forscher die Zeit, alle relevanten Informationen darüber zu sammeln. Ein Scheitern kommt für sie nicht in Frage, da sie die Idee aus allen Blickwinkeln analysieren. Dieser Unternehmertyp glaubt in der Regel daran, ein Unternehmen zu gründen, das eine hohe Erfolgschance hat, weil er viel Zeit und Ressourcen investiert, um alle Aspekte einer Geschäftsidee zu verstehen. Infolgedessen braucht dieser Unternehmertyp in der Regel sehr lange, um Produkte auf den Markt zu bringen und Entscheidungen zu treffen, weil er eine Grundlage für ein tiefes Verständnis benötigt. Diese Unternehmer verlassen sich viel mehr auf Daten und Fakten als auf Instinkt und Intuition."])},
  "entrepeneur_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "entrepeneur_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Hauptmerkmal dieses Unternehmertyps ist sein Reichtum. Diese Art von Unternehmern hat Geld und ist darauf spezialisiert, vielversprechende Unternehmen zu kaufen. Käufer-Unternehmer identifizieren das Unternehmen und bewerten seine Lebensfähigkeit, führen den Kauf durch und finden die am besten geeignete Person, um es zu führen und zu entwickeln."])},
  "HowErp_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Systeme sind für Unternehmen jeder Größe gedacht, ob klein, mittelgroß oder groß, die ihre Geschäftsprozesse und Daten an einem Ort integrieren wollen. Kein Unternehmen kann zu klein sein, um sein Geschäft vollständig unter Kontrolle zu bringen und intelligente Geschäftslösungen zur Vereinfachung seiner Geschäftsprozesse einzusetzen. Kleine Unternehmen nutzen ERP-Software, um die Kommunikation zwischen den Abteilungen zu verbessern und die Geschäftsprozesse bei vollständiger Transparenz aller Vorgänge zu optimieren."])},
  "HowErp_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen austauschen"])},
  "HowErp_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Integration von Geschäftsprozessen in eine Plattform erleichtert den Austausch von Daten und Informationen zwischen den Abteilungen."])},
  "HowErp_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steigerung der Effizienz"])},
  "HowErp_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienzsteigerung ohne Neueinstellungen oder Betriebserweiterungen durch die Automatisierung von Prozessen und die Beseitigung redundanter Arbeiten. Die Realität in kleinen Unternehmen sieht so aus, dass ein Mitarbeiter immer mehrere Aufgaben erledigt. Jeder packt mit an, wo und wann immer es nötig ist. ERP-Systeme, die auf kleine Unternehmen ausgerichtet sind, automatisieren wichtige Geschäftsfunktionen und erleichtern Ihren Mitarbeitern die Arbeit, so dass sie sich auf wichtigere Geschäftsaufgaben konzentrieren können."])},
  "HowErp_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Intelligence"])},
  "HowErp_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch den Einsatz eines ERP-Systems verfügen Sie stets über leicht zugängliche und genaue Daten. Diese Art von Systemen ermöglicht es Ihnen, fortschrittliche Berichte zu erstellen und Fortschritte, Produktivität und Auswirkungen zu überwachen. Auf diese Weise haben Sie stets einen vollständigen Einblick in Ihr Unternehmen und können bessere Entscheidungen treffen. Wie können Sie ohne ein umfassendes System, das Daten sammelt, Berichte über Ihre Prozesse und Systeme erstellt und das Gesamtbild betrachtet, genau wissen, wo Sie stehen?"])},
  "HowErp_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Transparenz und Geschäftskontrolle"])},
  "HowErp_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ein rentables Unternehmen zu führen, müssen Sie die vollständige Kontrolle über Ihre Unternehmensressourcen haben, d. h. über das, was Ihr Unternehmen an Bargeld, Rohstoffen, Fertigerzeugnissen, Waren, Personal und Produktionskapazitäten besitzt. Ihre Unternehmensressourcen sind das, was Sie zur Aufrechterhaltung Ihres Unternehmens verwenden, und Sie müssen jederzeit die vollständige Kontrolle über sie haben. Einer der größten Vorteile eines ERP-Systems besteht darin, dass es Ihnen einen vollständigen Überblick über Ihren gesamten Prozess verschafft, so dass Sie wissen, wie Ihr Prozess funktioniert. Mit ERP kann das, was früher stundenlanges sorgfältiges Sammeln von Daten und Erstellen von Berichten erforderte, automatisch erledigt werden, so dass Sie einen umfassenden Überblick über Ihr Unternehmen erhalten, der es Ihnen ermöglicht, wichtige Leistungskennzahlen über Echtzeit-Dashboards zu messen. Sie können diese Informationen nutzen, um immer zu wissen, wo Sie stehen, um Produktionspläne zu optimieren und um die Kapazität zu maximieren und Ihre Effizienz zu steigern."])},
  "software_solutions_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In vielen Fällen ist die ursprüngliche Motivation für die Anschaffung einer ERP-Software, d.h. die Ablösung des Alten durch das Neue, das Ziel, die Geschäftsprozesse zu vereinfachen, um einen nachhaltigen Wettbewerbsvorteil zu erzielen. Nicht selten sind ERP-Software-Implementierungen erfolglos. Anstatt Kostensenkungen, geschäftliche Flexibilität und Leistungsverbesserungen zu bringen, führen schlechte ERP-Systeme zu Komplexität, doppeltem Aufwand und im schlimmsten Fall zu schlechter Qualität und schlechtem Kundenservice sowie zu einem gefährlichen Mangel an Geschäftseinblicken, der ein Unternehmen in den Ruin treiben kann."])},
  "software_solutions_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt vor, dass implementierte ERP-Systeme nicht mit den Veränderungen Schritt halten. Das verarbeitende Gewerbe ist mit sich ständig ändernden Geschäftsprozessen, Daten und Anforderungen konfrontiert, die es für ein typisches, unflexibles ERP-System fast unmöglich machen, mit dem Schritt zu halten, was das Unternehmen wirklich braucht. Im Folgenden finden Sie einige Warnzeichen, die darauf hinweisen, dass Ihr Unternehmen gefährdet ist, weil die ERP-Software nicht so funktioniert, wie sie eigentlich sollte, oder weil sie nicht rechtzeitig aktualisiert wird."])},
  "software_solutions_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr ERP-System integriert keine Geschäftsdaten, die für das Erreichen des definierten Ziels wichtig sind. Ihre Daten sind in Ihrem veralteten ERP-System eingesperrt und schwer zugänglich. Aus diesem Grund können Sie die für die Entscheidungsfindung erforderlichen Daten nicht analysieren. Informationen und Daten im System zirkulieren nicht durch die Abteilungen, sondern sind in ihnen gefangen und existieren unabhängig voneinander. Dies erhöht die Komplexität und führt zu Doppelarbeit mit unterschiedlichen Versionen der Wahrheit, was die Qualität, Zuverlässigkeit und Verfügbarkeit wichtiger Informationen beeinträchtigt. Ohne Transparenz Ihrer gesamten Unternehmenssoftware und eine nahtlose Struktur, die alle Berichtsfunktionen und Abteilungen Ihrer Software miteinander verbindet, können Sie nicht alle wichtigen Informationen sammeln, die Ihr Unternehmen für den Erfolg benötigt."])},
  "software_solutions_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Änderung am System ist teuer und stellt einen langfristigen Prozess dar. Der Softwareanbieter stellt Ihnen nicht die Software-Upgrades zur Verfügung, die Sie benötigen. Auch die Softwarewartung ist zu teuer für eine so veraltete Software, die keine Innovationen bringt, was einen Verlust des Wettbewerbsvorteils bedeuten könnte. Sie sitzen zwischen einer dysfunktionalen Software, die keine Verbesserungen bringt, und explodierenden Kosten fest."])},
  "software_solutions_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Software-Upgrades stören das Geschäft. Wir haben bereits festgestellt, dass Upgrades von Softwareanbietern im Durchschnitt alle 12 bis 24 Monate herauskommen. Sie erfordern häufig eine Aktualisierung des Betriebssystems, des Datenbankverwaltungssystems, des Festplattenspeichers, der Hardware usw. Die Planung und Ausführung nimmt viel Zeit in Anspruch. Sie haben ein Problem, wenn Sie Ihr Geschäft eine Zeit lang ignorieren müssen, um Ihre Software zu aktualisieren. Außerdem bieten Software-Upgrades oft nicht das, was Ihr Unternehmen wirklich braucht. Wenn die Software nach einem Software-Upgrade nicht die Einstellungen bietet, die Sie und Ihr Unternehmen für das Wachstum benötigen, ist es Zeit für Veränderungen."])},
  "software_solutions_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht auf Ihre Unternehmenssoftware zugreifen, wenn Sie auf dem Weg zu einem Geschäftstreffen sind? Das bedeutet, dass Sie mit Ihrer Unternehmenssoftware nicht von unterwegs aus auf Daten zugreifen können. Es ist nur allzu offensichtlich, dass die Arbeit nicht aufhört, wenn man auf Reisen ist. Smartphones helfen Ihnen, in Verbindung zu bleiben, aber wenn Ihre ERP-Software keinen Zugriff auf mobile Apps hat, haben Sie Pech. Drahtlose Verbindungen gibt es überall, aber Sie sind trotzdem eingeschränkt, weil Sie nicht mit Ihren Geschäftsaktivitäten in Kontakt bleiben können. Ihre Kunden sind nicht alle an einem Ort, und das bedeutet, dass auch Sie nicht immer vor Ort sind. Es ist unerlässlich, dass Sie unterwegs per Knopfdruck auf Daten zugreifen können. Smartphones sind die halbe Lösung, aber wenn Ihr ERP-Anbieter keine vernetzte App anbietet, mit der Sie alle Ihre Daten in Echtzeit sehen können, werden Sie nicht das vollständige Bild Ihres Unternehmens erhalten, wenn Sie es brauchen."])},
  "software_solutions_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Mitarbeiter benötigen zu viel Zeit, um die Nutzung des Systems zu erlernen, da es zu kompliziert ist. Viele ältere ERP-Systeme sind schwer zu erlernen, und die Mitarbeiter geraten leicht in Stress, wenn sie aufgefordert werden, F1 zu drücken, um eine Anfrage zu stellen, oder die Eingabetaste zu drücken, um sie anzunehmen"])},
  "software_solutions_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP-Software ist eine umfassende Unternehmenslösung, die in verschiedenen Branchen und Geschäftsbereichen Anwendung findet. Daher muss Forspace ständig aktualisiert, erweitert und verbessert werden, um seinen Benutzern eine zufriedenstellende Nutzung und hochwertige Unterstützung bei der Lösung von Geschäftsproblemen zu bieten. Entscheiden Sie sich für Forspace, um eine reibungslose und erfolgreiche Durchführung von Geschäftsaktivitäten zu gewährleisten. Wir stehen unseren Kunden in jeder Phase zur Seite, und unsere Arbeit hört nicht auf, solange Sie unsere Dienste in Anspruch nehmen. Die Verbesserung Ihres Unternehmens ist das, was wir jeden Tag anstreben, denn: Wir arbeiten nicht für Sie, wir arbeiten mit Ihnen!"])},
  "digital_trans_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Unternehmen implementieren ERP-Software und tendieren dazu, die Gesamtleistung zu verbessern. In vielen Fällen ist die ursprüngliche Triebfeder, die zum Kauf von ERP-Software führt, d. h. die Ablösung des Alten durch das Neue, das Ziel der Vereinfachung von Geschäftsprozessen für einen nachhaltigen Wettbewerbsvorteil. Nicht selten sind ERP-Software-Implementierungen erfolglos. Anstatt Kostensenkungen, geschäftliche Flexibilität und Leistungsverbesserungen zu bringen, führen schlechte ERP-Systeme zu Komplexität, Doppelarbeit und im schlimmsten Fall zu schlechter Qualität und schlechtem Kundenservice sowie zu einem gefährlichen Mangel an Geschäftseinsicht, der ein Unternehmen in den Ruin treiben kann."])},
  "digital_trans_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie wird Buchhalter unterstützen, nicht ersetzen"])},
  "digital_trans_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt vor, dass implementierte ERP-Systeme nicht mit den Veränderungen Schritt halten. Das verarbeitende Gewerbe ist mit sich ständig ändernden Geschäftsprozessen, Daten und Anforderungen konfrontiert, die es einem typischen, unflexiblen ERP-System fast unmöglich machen, mit dem Schritt zu halten, was das Unternehmen wirklich braucht. Im Folgenden finden Sie einige Warnzeichen, an denen Sie erkennen können, dass das Geschäft Ihres Unternehmens gefährdet ist, weil die ERP-Software nicht so funktioniert, wie sie eigentlich sollte, oder weil es an rechtzeitigen Upgrades mangelt."])},
  "digital_trans_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorteile von Enterprise Resource Planning (ERP), Cloud Business und Automatisierungssoftware"])},
  "digital_trans_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr ERP-System integriert keine Geschäftsdaten, die für das Erreichen der definierten Mission wichtig sind. Ihre Daten sind in Ihrem veralteten ERP-System eingesperrt und schwer zugänglich. Aus diesem Grund können Sie die für die Entscheidungsfindung erforderlichen Daten nicht analysieren. Informationen und Daten im System zirkulieren nicht durch die Abteilungen, sondern sind in ihnen gefangen und existieren unabhängig voneinander. Dies erhöht die Komplexität und führt zu Doppelarbeit mit unterschiedlichen Versionen der Wahrheit, was die Qualität, Zuverlässigkeit und Verfügbarkeit wichtiger Informationen beeinträchtigt. Ohne Transparenz Ihrer gesamten Unternehmenssoftware und eine nahtlose Struktur, die alle Berichtsfunktionen und Abteilungen Ihrer Software miteinander verbindet, können Sie nicht alle wichtigen Informationen sammeln, die Ihr Unternehmen für den Erfolg benötigt."])},
  "digital_trans_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchhalter der Zukunft : Relevanter, produktiver und kreativer als je zuvor"])},
  "digital_trans_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Änderung im System ist teuer und stellt einen langfristigen Prozess dar. Der Softwareanbieter stellt Ihnen nicht die Software-Upgrades zur Verfügung, die Sie benötigen. Außerdem ist die Softwarewartung für eine so veraltete Software, die keine Innovationen bringt, zu teuer, was zu einem Verlust von Wettbewerbsvorteilen führen kann. Sie sitzen zwischen einer dysfunktionalen Software, die keine Verbesserungen bringt, und explodierenden Kosten fest."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "appointment_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbaren Sie einen Termin"])},
  "appointment_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie eine Präsentation der Software wünschen, hinterlassen Sie Ihre Daten und wir werden Sie kontaktieren."])},
  "appointment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbaren Sie einen Termin mit unserem Team, um weitere Informationen über unsere Produkte oder über Prozesse in Ihrem Unternehmen zu erhalten, die eine Softwarelösung und Beratung erfordern. Vielleicht haben wir die perfekte Lösung für Sie, basierend auf unseren Best Practices und Erfahrungen."])},
  "header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten internationale und nationale Speditionsdienstleistungen, Transport und Lagerung von Waren"])},
  "header_secondary_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten internationale und nationale Dienstleistungen für alle unsere Kunden zu transportieren, und wir sind auch spezialisiert auf dringende Verteilung von Waren bis zu den Kunden in Bosnien und Herzegowina."])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichent"])},
  "carina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoll"])},
  "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "widget_main_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA Šped wurde 2003 mit 5 Mitarbeitern als eines der Mitglieder der ASA PREVENT-Gruppe mit Haupttätigkeiten im Bereich internationaler Spedition, Transport und Lagerung gegründet. Von Beginn unserer Tätigkeit an bestand die Strategie darin, die personellen und materiellen Ressourcen vor allem durch die Entwicklung der Logistik in der Automobilindustrie und dann in anderen Wirtschaftszweigen zu entwickeln und zu stärken. Derzeit beschäftigt ASA ŠPED 43 Mitarbeiter, die Top-Experten auf dem Gebiet der von uns angebotenen Dienstleistungen sind. Teamwork und außergewöhnliche zwischenmenschliche Beziehungen zwischen unseren Mitarbeitern sind unser größter Wert."])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere neue Entwicklungsstrategie geht Partnerschaftsbeziehungen mit renommierten europäischen und globalen Unternehmen ein und basiert auf dem Konzept des Aufbaus und der Anmietung von Produktionskapazitäten, der Einstellung von Arbeitskräften und der vollständigen Logistikunterstützung (Transportlösungen, Zollabfertigungsprozesse, Softwareunterstützung ...)."])},
  "fast_and_secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnell und sicher!"])},
  "fast_and_simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnell und einfach"])},
  "find_us_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie uns auf der Karte"])},
  "duty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoll"])},
  "transport_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten internationale und nationale Transportdienstleistungen für alle unsere Kunden an und sind auch auf die dringende Verteilung von Waren an Kunden in Bosnien und Herzegowina spezialisiert."])},
  "warehouse_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA ŠPED verfügt über ca. 20.000 m² geschlossene Lagerfläche, die für die Lagerung aller Arten von Waren geeignet ist."])},
  "duty_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Unternehmen besteht aus professionellen und ausgebildeten Mitarbeitern im Bereich der Vermittlung bei der Zollabfertigung von Waren"])},
  "other_services_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Dienstleistungen"])},
  "contact_personel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA Šped Contact personel"])},
  "founded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegründet"])},
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie sich mit uns in sozialen Netzwerken"])},
  "transport_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten internationale und nationale Transportdienstleistungen für alle unsere Kunden, und wir sind auch spezialisiert auf dringende Verteilung von Waren an Kunden innerhalb von Bosnien und Herzegowina. Unter Transportdienstleistungen verstehen wir:"])},
  "road_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landverkehr"])},
  "maritime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See-/Containertransport"])},
  "air_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftverkehr"])},
  "partial_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweiser Transport"])},
  "intermodal_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermodaler Transport"])},
  "consulting_transport_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beratungsdienstleistungen im Zusammenhang mit der Optimierung von Transportkosten und Logistiklösungen"])},
  "International_domestic_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler_inländischer_Verkehr"])},
  "transport_of_dangerous_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport von gefährlichen ADR-Gütern"])},
  "transportation_of_clothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport von Textilien"])},
  "car_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport von Neuwagen"])},
  "special_transports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezialtransport"])},
  "transport_additional_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für viele unserer Kunden führen wir Expresslieferungen von Tür zu Tür durch, Just-in-Time, Just-in-Sequence als Lagerhaus auf Rädern."])},
  "transport_additional_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bearbeiten derzeit rund 1500 Transportanfragen pro Monat"])},
  "door_to_door_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen von Tür zu Tür"])},
  "warehouse_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In unseren Lagern bieten wir folgende Dienstleistungen an:"])},
  "warehouse_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenlager mit Be- und Entlademanipulationen"])},
  "warehouse_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochregallager"])},
  "warehouse_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waren verpacken"])},
  "warehouse_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positionierung"])},
  "warehouse_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortieren"])},
  "warehouse_li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilung"])},
  "warehouse_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vorgänge werden durch das WMS-System abgedeckt."])},
  "warehouse_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir besitzen auch ein öffentliches Zolllager vom Typ A, in dem wir derzeit viele Nutzer haben."])},
  "warehouse_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbezogen analysieren wir gemeinsam Prozesse und gestalten langfristige Lagerlogistiklösungen, in Bezug auf die Bedürfnisse unserer Kunden durch:"])},
  "warehouse_p4_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse-Methode"])},
  "warehouse_p4_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Wareneigenschaften"])},
  "warehouse_p4_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zusätzliche Arbeiten"])},
  "warehouse_p4_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notwendige Softwarelösungen"])},
  "warehouse_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Logistikzentrum ist in 2 Standorte unterteilt:"])},
  "warehouse_p5_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistikzentrum 1, das von 2003 bis 2017 gebaut wurde"])},
  "warehouse_p5_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtfläche von 45.000 m2, auf der:"])},
  "warehouse_p5_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33.000 m2 Produktions- und Geschäftsfläche + 2.500 m2 Bürofläche"])},
  "warehouse_p5_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistikzentrum 2, das von 2019 bis 2023 gebaut wird:"])},
  "warehouse_p5_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtfläche 48.500m2, auf der sich befindet: "])},
  "warehouse_p5_li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.000 m2 bebaute Produktions- und Geschäftsflächen, der Bau weiterer 12.000 m2 Produktions- und Geschäftsflächen ist derzeit im Gange."])},
  "warehouse_p5_li7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Standorte zeichnen sich durch die Nähe zu allen wichtigen Straßen, der Autobahn, dem Flughafen und dem Hauptzollterminal in Sarajevo aus. "])},
  "duty_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertretung der Kunden vor den Zollbehörden bei der Durchführung aller Zollverfahren:"])},
  "duty_p1_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importvorgang"])},
  "duty_p1_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportverfahren"])},
  "duty_p1_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transit"])},
  "duty_p1_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere zollrechtlich zulässige Verfahren"])},
  "duty_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für unsere Kunden haben wir Bankgarantien in Höhe von 4.000.000,00 KM für alle Arten von Zollverfahren gestellt."])},
  "duty_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind in allen Teilen und Grenzübergängen in BiH mit einem Netzwerk unserer Geschäftseinheiten und Subunternehmer vertreten."])},
  "duty_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten auch Zollabfertigungsdienste in der EU, der Türkei, Großbritannien und CEFTE- und EFTE-Unterzeichnerstaaten usw."])},
  "other_services_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beratungsleistungen in Bezug auf Versicherungen, Vorbereitung der Arbeitsausführung usw."])},
  "position_haris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführender Betriebsleiter"])},
  "position_nermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktor der Firma"])},
  "position_emir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiter des Sektors Lager"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten"])},
  "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail wurde erfolgreich versendet"])},
  "fill_the_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie das Formular aus"])},
  "iso_standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA ŠPED verfügt über ein internationales Qualitätszertifikat ISO 9001:2000 für die Durchführung von Tätigkeiten im Bereich der nationalen und internationalen Spedition und Lagerung, das wir seit 12 Jahren erfolgreich aufrechterhalten."])},
  "company_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen URL"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
  "done_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportaufträge erledigt"])},
  "done_terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zollanmeldungen erledigt"])},
  "transport_cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuwagen transportiert und eingelagert"])},
  "under_roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerung unter dem Dach"])},
  "under_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerüst im Bau"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])}
}