<template>
  <li v-for="(navItem, index) in routes" :key="navItem">
    <router-link v-if="navItem.name !== 'contact'" :to="navItem.to" class="nav"
      :class="[{ sidenav_r: sidenav_class }, { navy: navy }]" @click="showSideNav" @mouseover="giveindex(index)"
      ref="mainRoutes">
      <div class="d-flex justify-content-center">
        <div class="link-title" @click="checkIsRouteActive(navItem.name)">
          {{ $t(navItem.name) }}
         
        </div>
      </div>
    </router-link>
    <router-link v-if="navItem.name === 'contact' && !$isMobile()" :to="'/contact'" class="nav"
      :class="[{ sidenav_r: sidenav_class }, { navy: navy }]" @click="showSideNav" @mouseover="giveindex(5)"
      ref="mainRoutes">
      <div class="link-title" @click="checkIsRouteActive('contact')">
        {{ $t('contact') }}
      </div>
    </router-link>
  </li>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "Links",
  props: {
    routes: { type: Array },
    sidenav_class: { type: Boolean },
    showSideNav: { type: Function },
  },
  data() {
    return {
      visible: false,
      language: "Bosanski",
      selected: "bs",
      languages: [
        { language: "Bosanski", abr: "bs" },
        { language: "Engleski", abr: "en" },
        { language: "Njemački", abr: "de" },
      ],
      navy: false,
      isDropDownOpen: false,
    
      counter: 0,
      isExpanded: false
    };
  },
  computed: {
    fetchDropData() {
      return this.languages;
    },
  },
  methods: {
    ...mapMutations({ setSideNav: 'setSideNav' }),
  
    giveindex(index) {
      this.$emit("index", index);
    },
    translatePage(lang) {
      this.selected = lang.abr;
      this.language = lang.language;

      return (this.$i18n.locale = this.selected);
    },
    toggleActive(index, bin) {
      if (bin) {
        this.routes.forEach((item) => {
          item.isActive = index == item.id ? true : false;
        });
      } else {
        this.sidenavRoutes.forEach((item) => {
          item.isActive = index == item.id ? true : false;
        });
      }
    },
    displayContent(index) {
      this.contents.forEach((item) => {
        item.state = index == item.id ? true : false;
      });

      this.toggleActive(index, false);
    },
    showLanguages() {
      if (!this.visible) {
        this.visible = true;
      }
    },

    fadeOutDropDown() {
      this.isDropDownOpen = false
    },

    checkIsRouteActive(routeName) {
      console.log(this.$refs.mainRoutes[3].$el);
      //counter is added due to rendering issues. 
      //it controls how many times it is allowed to go through the function per click
      if (this.counter > 0 && this.counter < 2) {
        this.counter = 0
      }
      this.counter++
      if ( this.counter == 1) {
        this.$refs.mainRoutes[3].$el.classList.add('artificially-active')
      } else if (this.counter == 1) {
        this.$refs.mainRoutes[3].$el.classList.remove('artificially-active')
      }
    },
    checkIsProductActive(name) {
      if (name === 'products' && !this.isExpanded) {
        this.setSideNav(false)
        this.setIsProductsActive(true)
        this.isExpanded = true

      } else if (this.isExpanded && name === 'products') {
        this.setSideNav(false)
        this.isExpanded = false
        this.setIsProductsActive(false)
      }
      else {
        this.setIsProductsActive(false)
        this.setSideNav(true)
        this.isExpanded = false
      }
    }

  },
  mounted() {
    this.isProductsSubmenuActive = false
    window.addEventListener("scroll", () => {
      if (window.scrollY > 40) {
        this.navy = true;
      } else {
        this.navy = false;
      }
    });
    window.addEventListener("click", (ev) => {
      const classList = ev.target.className.split(" ")
      if (classList.includes('products-class')) {
        this.isDropDownOpen = true
      } else {
        this.isDropDownOpen = false
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.link-title {
  white-space: nowrap;
}

.arrow>img {
  height: 17px;
}

.arrow-up {
  transform: rotate(180deg);
  top: -2px;
}

.arrow-up>img {
  height: 17px;
  position: relative;
  left: -7px;
  top: -2px;
}

.arrow {
  position: relative;
  left: 7px;
}

li {
  list-style: none;
  position: relative;

  svg {
    width: 17px;
  }

  .nav {
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .underline {
      width: 100%;

      height: 3px;
    }

    &:hover {
      color: tomato;
      width: 100%;
      transition: width 0.3s ease-out;

    }
  }

  .navy {
    color: #333;
  }

  .sidenav {
    font-size: 23px;

    &:hover {
      cursor: pointer;
    }
  }

  .sidenav_r {
    color: rgb(156, 156, 156);
  }

  .router-link-active {

    color: tomato;

    &:hover {
      color: tomato;
      width: 100%;
      transition: width 0.3s ease-out;

    }
  }

  .router-link {
    text-align: left;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    display: none;
  }

}

@media screen and (max-width: 567px) {
  li {
    svg {
      display: none;
    }
  }

  .products-class {
    margin-left: 25px;

  }
}

.inactive {
  display: none;
}

.active {
  display: block;
}

.search {
  font-size: 20px;
}

.box {
  overflow: hidden;
  color: white;
  font-size: 20px;
  padding: 0px 10px;

  &:hover {
    cursor: pointer;
  }

  .menu {
    padding: 5px;
    background-color: white;
    color: #333;
    display: none;
    position: absolute;
    transform: translate(0%, 5%);

    p {
      padding-right: 30px;
      font-size: 17px;

      &:hover {
        background-color: rgb(190, 213, 255);
        cursor: pointer;
      }
    }
  }

  .visible {
    display: block;
  }
}

.items {
  margin-top: 3px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.97);
  padding: 20px 0px 0px 0px;
  width: 200px;
  color: rgb(63, 99, 146);
  border-left: 1px solid rgb(226, 225, 225);
  border-right: 1px solid rgb(226, 225, 225);
  border-bottom: 1px solid rgb(226, 225, 225);
  border-radius: 0 4px 4px 4px;

  color: #7a7a7a;

  & :hover {
    color: rgb(46, 78, 148);


  }

  a {
    color: rgb(114, 114, 114);
    margin: 3px 2px 12px 20px;
    font-size: 15px;
  }

  .router-link-active {
    color: rgb(90, 79, 59);
  }
}

.marker {
  position: absolute;
  top: 0px;
  left: 25px;
  background-color: rgba(53, 93, 145, 0.8);
  width: 35px;
  height: 4px;
}

.artificially-active>div {
  color: tomato;
}
</style>
